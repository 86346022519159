import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FormUtilitiesModule } from './ds24-ui/form-utilities';
import { WrapperComponent } from './components/wrapper/wrapper.component';
import { ToasterModule } from '@digistore/ds24-ui/toaster';

@NgModule({
  imports: [
    ToasterModule,
    FormUtilitiesModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  declarations: [WrapperComponent],
  exports: [WrapperComponent],
})
export class WidgetsModule {}
