import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  selector: 'ds24-floating-help-button',
  templateUrl: './floating-help-button.component.html',
  styleUrls: ['./floating-help-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingHelpButtonComponent {
  @Output() readonly execute = new EventEmitter<void>();
}
