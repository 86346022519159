<button (click)="execute.emit()">
  <span class="icon">
    <svg viewBox="0 0 74.2 74.2">
      <g>
        <path
          d="M37.1,74.2C16.7,74.2,0,57.6,0,37.1S16.7,0,37.1,0s37.1,16.7,37.1,37.1S57.6,74.2,37.1,74.2z M37.1,4.8C19.3,4.8,4.8,19.3,4.8,37.1c0,17.8,14.5,32.3,32.3,32.3s32.3-14.5,32.3-32.3C69.4,19.3,54.9,4.8,37.1,4.8z"
        ></path>
        <g>
          <path
            d="M29.4,22.7c1.9-2.1,4.6-3.1,8-3.1c3.1,0,5.6,0.9,7.5,2.6s2.8,4,2.8,6.7c0,1.7-0.3,3-1,4 c-0.7,1-2.1,2.6-4.2,4.6c-1.5,1.5-2.5,2.7-3,3.7c-0.3,0.7-0.5,1.5-0.6,2.6c-0.1,1.1-1,1.9-2.1,1.9h0c-1.2,0-2.2-1-2.1-2.3 c0.1-1.2,0.4-2.3,0.7-3.1c0.5-1.4,1.7-2.9,3.6-4.7l1.9-1.9c0.6-0.5,1-1.1,1.4-1.7c0.6-1,1-2.1,1-3.2c0-1.5-0.5-2.9-1.4-4 c-0.9-1.1-2.5-1.7-4.6-1.7c-2.7,0-4.5,1-5.5,2.9c-0.4,0.7-0.7,1.7-0.8,2.8c-0.1,1-1,1.8-2.1,1.8h0c-1.3,0-2.3-1.1-2.1-2.4 C27.1,26.2,28,24.3,29.4,22.7z M36.9,49.7L36.9,49.7c1.3,0,2.3,1,2.3,2.3v0.2c0,1.3-1,2.3-2.3,2.3h0c-1.3,0-2.3-1-2.3-2.3V52 C34.5,50.7,35.6,49.7,36.9,49.7z"
          ></path>
        </g>
      </g>
    </svg>
  </span>
  <span class="label" i18n="@@core.button.help">Hilfe</span>
</button>
