import { Injector } from '@angular/core';
import { StepsManagerModule } from '../components/pickers/steps-manager/steps-manager.module';

const LOADED_COMPONENTS = {};

export const lazyLoadComponent = async (
  component: string,
  injector: Injector
): Promise<void> => {
  if (LOADED_COMPONENTS[component]) {
    return;
  }
  LOADED_COMPONENTS[component] = true;

  let module;
  let importedWidgetModule;
  switch (component) {
    case 'UpsellOverview':
      importedWidgetModule = await import(
        '../components/upsell-overview/upsell-overview.module'
      );
      module = importedWidgetModule.UpsellOverviewModule;
      break;
    case 'ProductList':
      importedWidgetModule = await import(
        '../components/product-list/product-list.module'
      );
      module = importedWidgetModule.ProductListModule;
      break;
    case 'InputAddress':
      importedWidgetModule = await import(
        '../components/input-address/input-address.module'
      );
      module = importedWidgetModule.InputAddressModule;
      break;
    case 'InputAdditional':
      importedWidgetModule = await import(
        '../components/input-additional/input-additional.module'
      );
      module = importedWidgetModule.InputAdditionalModule;
      break;
    case 'InputCheckboxes':
      importedWidgetModule = await import(
        '../components/input-checkboxes/input-checkboxes.module'
      );
      module = importedWidgetModule.InputCheckboxesModule;
      break;
    case 'CurrencySelector':
      importedWidgetModule = await import(
        '../components/currency-selector/currency-selector.module'
      );
      module = importedWidgetModule.CurrencySelectorModule;
      break;
    case 'GuaranteeSeal':
      importedWidgetModule = await import(
        '../components/guarantee-seal/guarantee-seal.module'
      );
      module = importedWidgetModule.GuaranteeSealModule;
      break;
    case 'InputVoucher':
      importedWidgetModule = await import(
        '../components/input-voucher/input-voucher.module'
      );
      module = importedWidgetModule.InputVoucherModule;
      break;
    case 'LanguageSelector':
      importedWidgetModule = await import(
        '../components/language-selector/language-selector.module'
      );
      module = importedWidgetModule.LanguageSelectorModule;
      break;
    case 'LegalNotice':
      importedWidgetModule = await import(
        '../components/legal-notice/legal-notice.module'
      );
      module = importedWidgetModule.LegalNoticeModule;
      break;
    case 'OrderBump':
      importedWidgetModule = await import(
        '../components/order-bump/order-bump.module'
      );
      module = importedWidgetModule.OrderBumpModule;
      break;
    case 'OrderSummary':
      importedWidgetModule = await import(
        '../components/order-summary/order-summary.module'
      );
      module = importedWidgetModule.OrderSummaryModule;
      break;
    case 'PaymentMethods':
      importedWidgetModule = await import(
        '../components/payment-methods/payment-methods.module'
      );
      module = importedWidgetModule.PaymentMethodsModule;
      break;
    case 'BuyButton':
      importedWidgetModule = await import(
        '../components/buy-button/buy-button.module'
      );
      module = importedWidgetModule.BuyButtonModule;
      break;
    case 'Button':
      importedWidgetModule = await import('../components/button/button.module');
      module = importedWidgetModule.ButtonModule;
      break;
    case 'PaymentPlans':
      importedWidgetModule = await import(
        '../components/payment-plans/payment-plans.module'
      );
      module = importedWidgetModule.PaymentPlansModule;
      break;
    case 'Footer':
      importedWidgetModule = await import('../components/footer/footer.module');
      module = importedWidgetModule.FooterModule;
      break;
    case 'QuantityDiscount':
      importedWidgetModule = await import(
        '../components/product-list/quantity-discount/quantity-discount.module'
      );
      module = importedWidgetModule.QuantityDiscountModule;
      break;
    case 'PickerStepsManager':
      importedWidgetModule = await import(
        '../components/pickers/steps-manager/steps-manager.module'
      );
      module = importedWidgetModule.StepsManagerModule;
      break;
    case 'PickerProductSelect':
      importedWidgetModule = await import(
        '../components/pickers/product-select/product-select.module'
      );
      module = importedWidgetModule.ProductSelectModule;
      break;
    case 'PickerProductLanguageSelect':
      importedWidgetModule = await import(
        '../components/pickers/product-language-select/product-language-select.module'
      );
      module = importedWidgetModule.ProductLanguageSelectModule;
      break;
    case 'WidgetStyler':
      importedWidgetModule = await import(
        '../components/widget-styler/widget-styler.module'
      );
      module = importedWidgetModule.WidgetStylerModule;
      break;
    case 'Notifications':
      importedWidgetModule = await import(
        '../components/notifications/notifications.module'
      );
      module = importedWidgetModule.NotificationsModule;
      break;
  }

  module.initialize(injector);
};
