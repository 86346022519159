import { Injectable } from '@angular/core';
import { ASSETS_PATH_ABSOLUTE } from '../pipes/asset.pipe';

@Injectable({
  providedIn: 'root',
})
export class WaitingService {
  staticBrand = (window as any).SITEOWNER_BRAND;

  private _div: HTMLElement;

  constructor() {}

  private _createLayer() {
    const message = $localize`:@@common.label.loading_message:Einen Moment bitte - Digistore24 verarbeitet Ihre Bestellung ...`;
    this._div = document.createElement('div');
    this._div.setAttribute('class', 'waiting-layer visible');
    this._div.innerHTML = `
      <div class="box">
        <svg class="spinner" viewBox="0 0 200 100">
          <rect rx="15" ry="15" x="2" y="2" width="196" height="96" fill="none" class="path"
            stroke-width="3"
            stroke-miterlimit="10"></rect>
          <rect rx="15" ry="15" x="2" y="2" width="196" height="96" fill="none" class="path2"
            stroke-width="3"
            stroke-miterlimit="10"></rect>
        </svg>
        <div class="logo" style="background-image: url('${ASSETS_PATH_ABSOLUTE}de/assets/brand/${this.staticBrand}/logo/logo.svg')"></div>
        <div class="message">${message}</div>
      </div>
    </div>`;
    document.body.appendChild(this._div);
  }

  public show() {
    if (!this._div) {
      this._createLayer();
      return;
    }
    this._div.classList.add('visible');
  }

  public hide() {
    if (!this._div) {
      return;
    }
    this._div.classList.remove('visible');
  }
}
