import { Unsubscribable } from 'rxjs';

export class AdvancedEventEmitter<T, R> {
  private _listenerCount = 0;
  private _listener: {
    [id: number]: (resultListBefore?: R[], value?: T) => Promise<R>;
  } = {};

  async emit(value?: T): Promise<R[]> {
    /**
     * ruft sequenziell alle listener auf und
     * teil d
     */
    return await Object.keys(this._listener)
      .sort()
      .reduce<Promise<R[]>>(async (result, listenerId) => {
        const arr = await result;
        arr.push(await this._listener[listenerId](arr, value));
        return arr;
      }, Promise.resolve([]));
  }

  subscribe(
    callback: (resultListBefore?: R[], value?: T) => Promise<R>
  ): Unsubscribable {
    const id = this._listenerCount++;
    this._listener[id] = callback;
    return {
      unsubscribe: ((listenerId) => {
        return () => delete this._listener[listenerId];
      })(id),
    };
  }
}
