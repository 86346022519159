import { ActionReducer, INIT, UPDATE } from '@ngrx/store';
import { OrderformState } from '@orderform/widgets';

class AppState {}

/* istanbul ignore next */
export function initStateFromEmbeddedData(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return function (state, action) {
    const newState = reducer(state, action) as OrderformState;
    if ([INIT.toString(), UPDATE.toString()].includes(action.type)) {
      return {
        ...state,
        order: (window as any).order_data,
      };
    }
    return newState;
  };
}
