import { Injectable } from '@angular/core';
import { OrderformFascade } from '../fascades/orderform.fascade';
import { debounceTime, map } from 'rxjs/operators';
import { InterfacePost } from '@orderform/widgets';
import { ResizeObserver } from '@juggle/resize-observer';
import { BehaviorSubject, firstValueFrom, ReplaySubject } from 'rxjs';
import { getGETParameters } from '../misc/page-parameter';

interface OrderformAdressDTO {
  i_order_as?: 'private' | 'business';
  company?: string;
  first_name?: string;
  last_name?: string;
  street?: string;
  street_number?: string;
  zip_code?: string;
  city?: string;
  country?: string;
  phone_no?: string;
  tax_id?: string;
}

type VagueBool = 'Y' | 'N' | '1' | '0' | boolean | number;

@Injectable({
  providedIn: 'root',
})
export class OrderformIframeService {
  readonly iframeActive: boolean = !!(
    getGETParameters('iframe') || getGETParameters('is_in_iframe')
  );
  private sentHeight;
  private iframeIndex;
  private body: HTMLBodyElement;
  private updateTimeoutId;
  private sendHeightTimeoutId;

  scrollPosition$ = new BehaviorSubject(0);

  paymentPlanList$ = this._fascade.paymentPlanList$.pipe(
    map((paymentPlanItems) => paymentPlanItems)
  );

  productList$ = this._fascade.orderItems$.pipe(
    map((orderItems) => orderItems)
  );

  order$ = this._fascade.order$.pipe(map((order) => order.order));

  ResizeObserver$ = new ReplaySubject(1);

  constructor(private _fascade: OrderformFascade) {}

  init() {
    if (!this.iframeActive) {
      return;
    }

    this.body = document.getElementsByTagName('body')[0];
    this.body.style.overflowY = 'hidden';

    this.ResizeObserver$.pipe(debounceTime(200)).subscribe(() => {
      this.ds24_sendIframeHeightMsg('setPayFrameHeight', null);
    });

    new ResizeObserver(() => {
      this.ResizeObserver$.next(true);
    }).observe(this.body);

    window.addEventListener('message', this.ds24_handlePostMessage.bind(this));
  }

  static isTrue(vague: VagueBool): boolean {
    return vague === true || vague === 'Y' || vague === '1' || vague === 1;
  }

  static removeUrlParam(url: string, paramName: string) {
    const [urlString, urlParamString] = url.split('?');
    if (!urlParamString) {
      return url;
    }

    const prefix = encodeURIComponent(paramName) + '=';
    const params = urlParamString.split(/[&;]/g);

    for (let i = params.length - 1; i >= 0; i--) {
      if (params[i].lastIndexOf(prefix, 0) !== -1) {
        params.splice(i, 1);
      }
    }

    return urlString + (params.length > 0 ? '?' + params.join('&') : '');
  }

  async ds24_handlePostMessage(e) {
    if (!e.data) {
      return;
    }

    let data;

    try {
      data = JSON.parse(e.data);
    } catch (err) {
      console.log(err);
      return;
    }

    if (!data) {
      return;
    }

    const action = data.ds24action;
    if (!action) {
      return;
    }

    this.iframeIndex = data.iframe_index;

    switch (action) {
      case 'setFocusOnFirstInput':
        return this.ds24_setFocusOnFirstInput();

      case 'setCancelUrl':
        return this.ds24_setCancelUrl(data.payload.cancel_url);

      case 'setEditorMode':
        return this.ds24_setEditorMode();

      case 'requestPayFrameReady':
        return this.ds24_sendPayFrameReady();

      case 'setContactData':
        return this.ds24_setContactData(data.payload);

      case 'setStepNo':
        // Currently not used (just for MSOB)
        // ds24_setWidgetTab( data.payload.step_no, DS24_STEP_COUNT );
        break;

      case 'setPaymentPlan':
        // IMPORTANT: Index starts at 1!
        return this.ds24_setPaymentPlan(
          parseInt(data.payload.plan_index_or_id, 10)
        );

      case 'setQuantity':
        // IMPORTANT: Index starts at 1!
        return this.ds24_setQuantity(
          parseInt(data.payload.product_index_or_id, 10),
          parseInt(data.payload.quantity, 10)
        );

      case 'setCurrency':
        return this.ds24_setCurrency(data.payload.currency);

      case 'setDiscountVoucher':
        return this.ds24_setDiscountVoucher(
          data.payload.voucher_code,
          data.payload.lock_input,
          data.payload.show_message
        );

      case 'reload':
        return this.ds24_reload();

      case 'showDummyErrorMessage':
        // Currently not used
        // ds24_showDummyErrorMessages( data.payload.are_messages_shown );
        break;

      case 'setAffiliate':
        return this.ds24_setAffiliate(
          data.payload.affiliate,
          data.payload.campaignkey
        );

      case 'setTrackingkey':
        return this.ds24_setTrackingKey(data.payload.tracking_key);

      case 'setTracking':
        return this.ds24_setTracking(
          data.payload.affiliate,
          data.payload.campaignkey,
          data.payload.tracking_key,
          data.payload.custom
        );

      case 'setCustomParam':
        return this.ds24_setCustomParam(data.payload.custom);

      case 'receiveScrollPosition':
        return this.ds24_receiveScrollPosition(
          data.payload.window_offset.top,
          data.payload.iframe_offset.top
        );

      case 'setIframeParentURL':
        return this.ds24_setIframeParentURL(data.payload.iframe_parent_url);
    }
  }

  ds24_sendIframeMsg(action, payload) {
    if (!parent.postMessage) {
      return;
    }

    const data = {
      ds24action: action,
      payload: payload,
      new_step_no: 0, // DS24_STEP_NUMBER,  MSOB-related - not yet used
      total_step_count: 1, // DS24_STEP_COUNT,  MSOB-related - not yet used
      iframe_index: this.iframeIndex,
    };

    parent.postMessage(JSON.stringify(data), '*');
  }

  /**
   * Methods to let the host application scroll to position (y) of an element in the iframe
   * @param positionY
   */
  scrollToIframeElementPosition(positionY: number = 0) {
    if (this.iframeActive) {
      this.ds24_sendIframeHeightMsg('setPayFrameHeight', positionY);
    }
  }

  ds24_sendIframeHeightMsg(event, scroll_to = null) {
    if (typeof this.sentHeight === 'undefined') {
      this.sentHeight = 0;
    }

    const height = this.body.clientHeight + 15;
    const payload = {};
    payload['height'] = height;

    if (this.sendHeightTimeoutId) {
      clearTimeout(this.sendHeightTimeoutId);
    }

    this.sendHeightTimeoutId = setTimeout(() => {
      if (typeof scroll_to !== 'object') {
        payload['scroll_to'] = scroll_to;
      } // Return only on same height when no scroll position is not set
      else if (this.sentHeight === height && scroll_to === null) {
        return;
      }

      this.ds24_sendIframeMsg(event, payload);
      this.sentHeight = height;
    }, 100);
  }

  ds24_sendPayFrameReady() {
    this.ds24_sendIframeHeightMsg('setPayFrameHeight', null);
  }

  ds24_sendScrollPosition() {
    this.ds24_sendIframeMsg('sendScrollPosition', {});
  }

  ds24_setFocusOnFirstInput() {
    document.querySelectorAll<HTMLElement>('input[type=text]').item(0)?.focus();
  }

  async ds24_setContactData(address: OrderformAdressDTO) {
    const shipping_address = {};

    for (const [key, value] of Object.entries(address)) {
      if (key === 'zip_code') {
        shipping_address['buyer_address_zipcode'] = value;
        continue;
      }
      shipping_address['buyer_address_' + key] = value;
    }

    await this.updateStore({
      shipping_address,
    });
  }

  async ds24_setCancelUrl(url: string) {
    await this.updateHiddenInput({
      set_cancel_url: OrderformIframeService.removeUrlParam(url, 'token'),
    });
  }

  async ds24_setEditorMode() {
    const order = await firstValueFrom(this.order$);

    await this.updateHiddenInput({
      editor_mode: !order.hidden_inputs['editor_mode'],
    });
  }

  async ds24_setPaymentPlan(planIndexOrId: number) {
    const showError = () =>
      console.log(
        `ds24_setPaymentPlan(): invalid value for plan_index_or_id: ${planIndexOrId}`
      );

    if (planIndexOrId <= 0) {
      return showError();
    }

    let planId = planIndexOrId;
    if (planIndexOrId < 20) {
      const paymentPlanList = await firstValueFrom(this.paymentPlanList$);

      if (planIndexOrId > paymentPlanList.length) {
        return showError();
      }

      planId = parseInt(paymentPlanList[planIndexOrId - 1].payment_plan_id, 10);
    }

    await this.updateStore({
      payment_plan_id: '' + planId,
    });
  }

  async ds24_setQuantity(productIndexOrId: number, quantity: number) {
    if (productIndexOrId <= 0) {
      return console.error(
        'Zero or negative value for product_index_or_id: ' + productIndexOrId
      );
    }

    if (isNaN(quantity)) {
      quantity = 1;
    }

    if (quantity <= 0) {
      quantity = 0;
    }

    if (quantity > 10000) {
      quantity = 10000;
    }

    const productList = await firstValueFrom(this.productList$);

    let productId;
    // it was "<= 100" in old code
    if (productIndexOrId < 20) {
      // Use as index
      for (const [key, value] of Object.entries(productList)) {
        if (value.list_index === productIndexOrId - 1) {
          productId = parseInt(key, 10);
          break;
        }
      }
    } else {
      // use as id
      productId = productList[productIndexOrId] ? productIndexOrId : undefined;
    }

    if (typeof productId == 'undefined' || !productId) {
      return console.error(
        'Invalid value for product_index_or_id: ' + productIndexOrId
      );
    }

    const items = {
      ...productList,
      [productId]: {
        ...productList[productId],
        quantity: { value: quantity },
      },
    };

    await this.updateStore({
      items,
    });
  }

  async ds24_setCurrency(currency: string) {
    await this.updateStore({
      currency_code: currency,
    });
  }

  async ds24_setIframeParentURL(URL: string) {
    this._fascade.setOrderStore({
      iframe_parent_url: URL,
    });
  }

  async ds24_setDiscountVoucher(
    voucherCode: string,
    _lockInput: VagueBool,
    _showMessage: VagueBool
  ) {
    await this.updateStore({
      voucher_code: voucherCode,
      voucher_code_added: true,
    });
  }

  async ds24_setAffiliate(affiliate: string, campaignKey: string) {
    await this.updateHiddenInput({
      aff: affiliate ?? '',
      cam: affiliate && campaignKey ? campaignKey : '',
    });
  }

  async ds24_setTrackingKey(trackingKey: string) {
    await this.updateHiddenInput({
      ds24tr: trackingKey ?? '',
    });
  }

  async ds24_setTracking(
    affiliate: string,
    campaignKey: string,
    trackingKey: string,
    custom: string
  ) {
    await this.updateHiddenInput({
      aff: affiliate ?? '',
      cam: affiliate && campaignKey ? campaignKey : '',
      ds24tr: trackingKey ?? '',
      custom: custom ?? '',
    });
  }

  async ds24_setCustomParam(custom: string) {
    await this.updateHiddenInput({
      custom: custom ?? '',
    });
  }

  ds24_reload() {
    window.location.reload();
  }

  ds24_receiveScrollPosition(windowOffset: number, iframeOffset: number) {
    this.scrollPosition$.next(windowOffset - iframeOffset);
  }

  async updateHiddenInput(
    updatedHiddenInputs: Partial<InterfacePost['hidden_inputs']>
  ) {
    const order = await firstValueFrom(this.order$);

    await this.updateStore({
      hidden_inputs: {
        ...order.hidden_inputs,
        ...updatedHiddenInputs,
      },
    });
  }

  async updateStore(incrementalUpdateObject: Partial<InterfacePost>) {
    this._fascade.setOrderStore(incrementalUpdateObject);

    if (this.updateTimeoutId) {
      clearTimeout(this.updateTimeoutId);
    }

    this.updateTimeoutId = setTimeout(() => {
      firstValueFrom(this._fascade.update$());
    }, 1000);
  }
}
