import { Action, createReducer, on } from '@ngrx/store';
import * as cloneDeep from 'lodash/cloneDeep';
import * as merge from 'lodash/merge';
import * as OrderformActions from './orderform.actions';
import {
  OrderformOrderOrderState,
  OrderformOrderState,
  OrderformState,
} from './orderform.models';
import { objectDifference } from '../misc/object-difference';

export const ORDERFORM_FEATURE_KEY = 'orderform';

export interface OrderformPartialState {
  readonly [ORDERFORM_FEATURE_KEY]: OrderformState;
}

export const initialState: OrderformState = {
  order: {
    order: {} as OrderformOrderOrderState,
  } as OrderformOrderState,
  paymentUrl: '',
  pending: false,
  processing: false,
};

const orderformReducer = createReducer(
  initialState,
  on(OrderformActions.formValidation, (state, {}) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        execute: {
          ...state.order.execute,
          action: 'submit',
        },
      },
      pending: true,
    };
  }),
  on(OrderformActions.updateOrderformRequest, (state, { data }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        execute: {
          ...state.order.execute,
          action: 'select',
        },
        requestedOrder: cloneDeep(state.order.order),
      },
      pending: true,
    };
  }),
  on(OrderformActions.validateOrderformRequest, (state, { data }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        execute: {
          ...state.order.execute,
          action: 'check',
        },
      },
      pending: true,
    };
  }),
  on(OrderformActions.sendOrderFormRequest, (state, { data }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        execute: {
          ...state.order.execute,
          action: 'submit',
        },
      },
      paymentUrl: '',
      pending: true,
      processing: true,
    };
  }),
  on(OrderformActions.updateOrderformSuccess, (state, { data }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        ...data,
        order: merge(
          cloneDeep(data.order),
          objectDifference(state.order.order, state.order.requestedOrder)
        ),
        requestedOrder: {},
      },
      pending: false,
      processing: false,
    };
  }),
  on(
    OrderformActions.sendOrderFormRequestSuccess,
    OrderformActions.validateOrderformRequestSuccess,
    (state, { data }) => {
      return <OrderformState>{
        ...state,
        order: {
          ...state.order,
          ...data,
        },
        pending: false,
        processing: false,
      };
    }
  ),
  on(
    OrderformActions.validateOrderformRequestError,
    OrderformActions.updateOrderformError,
    OrderformActions.sendOrderFormRequestError,
    OrderformActions.formValidationFailed,
    OrderformActions.beforeSubmitOrderformFailed,
    OrderformActions.beforeSubmitOrderformSuccess,
    OrderformActions.afterSubmitOrderFormFailed,
    (state, {}) => {
      return <OrderformState>{
        ...state,
        pending: false,
        processing: false,
      };
    }
  ),

  on(OrderformActions.setToOrderStore, (state, { update }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        order: {
          ...state.order.order,
          ...cloneDeep(update),
        },
      },
    };
  }),

  on(OrderformActions.updateConfigProductId, (state, { productId }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        settings: {
          ...state.order.settings,
          config: {
            ...state.order.settings.config,
            product_id: productId,
          },
        },
      },
    };
  }),

  on(
    OrderformActions.sendOrderFormRequestPaymentUrlResponse,
    (state, { url }) => {
      return {
        ...state,
        pending: false,
        paymentUrl: url,
      };
    }
  ),

  on(OrderformActions.checkDoubleOrderResponse, (state, { data }) => {
    if (data.type === 'hidden') {
      return state;
    }
    return {
      ...state,
      order: {
        ...state.order,
        messages: {
          ...state.order.messages,
          notifications: [data],
        },
      },
    };
  })
);

export function reducer(state: OrderformState | undefined, action: Action) {
  return orderformReducer(state, action);
}
