import { createAction, props } from '@ngrx/store';
import {
  CheckDoubleOrderResponse,
  InterfacePost,
  OrderformOrderState,
  OrderformRedirectResponse,
} from './orderform.models';

/**
 * Set values to store
 */

export const setToOrderStore = createAction(
  '[Orderform] Update order store',
  props<{ update: InterfacePost }>()
);

/**
 * Validate
 */
export const validateOrderformRequest = createAction(
  '[Orderform] Validate Voucher Request',
  props<{ data: InterfacePost }>()
);

export const validateOrderformRequestSuccess = createAction(
  '[Orderform] Validate Success',
  props<{ data: OrderformOrderState }>()
);

export const validateOrderformRequestError = createAction(
  '[Orderform] Validate Error',
  props<{ error: any }>()
);

/**
 * Update
 */

export const updateOrderformRequest = createAction(
  '[Orderform] Update Request',
  props<{ data: InterfacePost }>()
);

export const updateOrderformSuccess = createAction(
  '[Orderform] Update Success',
  props<{ data: OrderformOrderState }>()
);

export const updateOrderformError = createAction(
  '[Orderform] Update Error',
  props<{ error: any }>()
);

export const formValidation = createAction('[Orderform] Form Valdiation');

export const formValidationFailed = createAction(
  '[Orderform] Form Validation Failed'
);

export const formValidationSuccess = createAction(
  '[Orderform] Form Validation Success'
);

export const beforeSubmitOrderformFailed = createAction(
  '[Orderform] Before Submit Orderform Failed'
);

export const beforeSubmitOrderformSuccess = createAction(
  '[Orderform] Before Submit Validation Success'
);

export const afterSubmitOrderFormFailed = createAction(
  '[Orderform] After Submit Failed'
);

/**
 * Send
 */
export const sendOrderFormRequest = createAction(
  '[Orderform] Send',
  props<{
    data: InterfacePost;
  }>()
);

export const sendOrderFormRequestSuccess = createAction(
  '[Orderform] Send Success',
  props<{ data: OrderformOrderState }>()
);

export const sendOrderFormRequestRedirect = createAction(
  '[Orderform] Send Redirect',
  props<{ data: OrderformRedirectResponse }>()
);

export const sendOrderFormResponseShowMessage = createAction(
  '[Orderform] Response Show Message',
  props<{ data: OrderformRedirectResponse }>()
);

export const sendOrderFormRequestPaymentUrlResponse = createAction(
  '[Orderform] Send Payment Url',
  props<{ url: string }>()
);

export const sendOrderFormRequestError = createAction(
  '[Orderform] Send Error',
  props<{ error: any }>()
);

export const updateConfigProductId = createAction(
  '[Orderform] Update Config Product ID',
  props<{ productId: number }>()
);

export const checkDoubleOrderRequest = createAction(
  '[Orderform] Check double order Request',
  props<{ email: string }>()
);

export const checkDoubleOrderResponse = createAction(
  '[Orderform] Check double order Response',
  props<{ data: CheckDoubleOrderResponse }>()
);

export const checkDoubleOrderError = createAction(
  '[Orderform] Check double order Error',
  props<{ error: any }>()
);

export const callGenericAjaxUrlRequest = createAction(
  '[Orderform] Call generic ajaxUrl Request',
  props<{ ajaxUrl: string }>()
);
